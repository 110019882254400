<template>
  <div>
  <b-card class="shadow-none chart"><h4>Total Broadcast Time <span style="font-size: 12px; display: inline-block; vertical-align: middle;">(Jan. 05, 2022 - Now)</span></h4>
  <hr>
  <div>
    <span class="accent">{{stats.broadcast_time.hours}}</span> Hours ({{stats.broadcast_time.days}} Days)
  </div>
  </b-card>

  <b-card class="shadow-none chart"><h4>Registered Users <span style="font-size: 12px; display: inline-block; vertical-align: middle;"></span></h4>
    <hr>
    <div>
      <span class="accent">{{stats.users.total}}</span> Brimers
    </div>
    </b-card>

    <b-card class="shadow-none chart"><h4>Multistream Stats <span style="font-size: 12px; display: inline-block; vertical-align: middle;"></span></h4>
      <hr>
      <div>
        <span class="accent">{{stats.multistream.total}}</span> Connected Multistream Destinations
      </div>
      </b-card>

      <b-card class="shadow-none chart"><h4>Chat Stats <span style="font-size: 12px; display: inline-block; vertical-align: middle;"></span></h4>
        <hr>
        <div>
          <span class="accent">{{stats.chat_msgs.total}}</span> Chat Messages Sent
        </div>
        </b-card>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import Vue from 'vue'
import {BCard} from 'bootstrap-vue'

Vue.component('apexchart', VueApexCharts)
/*  eslint-disable */ 
export default {
  mounted(){
document.title = 'Stats - Brime Live'
  },
  components: {
    BCard,
  },
  async mounted() {
    this.stats = [9200, 9878, 10300, 16580, 18332, 18590, 19283]
    const response = await this.getPlatformStats()
    this.stats = response
  },
  data() {
      return {
       stats: {
"broadcast_time": {
"hours": 3980,
"days": 165
},
"users": {
"total": 19739
},
"multistream": {
"total": 146
},
"chat_msgs": {
"total": 10268
}
}
      }
    },
    watch: {
      stats() {
        if(this.stats.broadcast_time) {
          this.stats.broadcast_time.hours = this.numberWithCommas(this.stats.broadcast_time.hours)
          this.stats.users.total = this.numberWithCommas(this.stats.users.total)
          this.stats.chat_msgs.total = this.numberWithCommas(this.stats.chat_msgs.total)
        }

      },
    },
     methods: {
      numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
},
  updateChart() {
   const max = 90;
   const min = 20;
   const newData = this.stats
   // In the same way, update the series option
   this.series = [{
    data: newData
   }]
  }
 }
};
</script>
<style scoped>
.accent {
  color: #7367f0;
  font-weight: bold;
}
.chart{
width: 50%;
}
@media all and (max-width: 767px) {
.chart{
width: 100% !important;
}
}
</style>